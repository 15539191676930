<template>
  <div class="about-us">
    <div class="base-info">
      <div class="base-title">关于我们</div>
      <div class="base-wrap">
        <div class="base-item">上海嗨喵网络科技有限公司 (西瓜互动)</div>
        <div class="base-item">成立于2020年，上海高新企业、软件企业认证。</div>
        <div class="base-item">20W+ 场活动成功案例，让客户100%满意。</div>
        <div class="base-item">
          6大领域全覆盖:电商、小程序、App、门户、企业管理、互动营销。
        </div>
        <div class="base-item">
          24个自主知识产权系统:从基础框架到各个领域应用的成熟系统。
        </div>
        <div class="base-item">
          众多500强客户:新浪、腾讯、百度、中国银行、中国电信。
        </div>
      </div>
      <div class="contact-wrap">
        <div class="contact-item">地址：上海普陀区中江路118号</div>
        <div class="contact-item">电话：19512368757 (00:00-24:00)</div>
        <div class="contact-item">邮箱：bd@hudongmiao.com</div>
      </div>
      <!-- <div class="qrcode"></div> -->
      <img src="https://ustatic.hudongmiao.com/%20miao/watermalen/qrcode.jpg" class="qrcode" />
    </div>
    <div class="form-info">
      <div class="form-title">快速发送需求</div>
      <div class="form-item-wrap">
        <input
          type="text"
          class="form-input"
          placeholder="您的姓名"
          v-model="content"
        />
        <input
          type="text"
          class="form-input"
          placeholder="您的电话"
          v-model="phone"
        />
        <textarea
          v-model="remark"
          name=""
          id=""
          cols="30"
          rows="10"
          class="form-textarea"
          placeholder="您的需求描述，例需求类型，功能/设计要求，时间安排等"
        ></textarea>
        <div class="button-info">
          <div class="say">
            <div>我们将会在1个工作日回复您，您的个人</div>

            <div>信息将会作保密处理。</div>
          </div>
          <div class="btn" @click="submit">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { putRecord } from '@/api/index';

export default {
  name: 'AboutUs',
  data() {
    return {
      remark: '',
      phone: '',
      content: '',
    };
  },
  created() {},
  methods: {
    submit() {
      const { remark, phone, content } = this;
      if (!content) {
        this.$message.error('请填写姓名');
        return;
      }
      if (!phone) {
        this.$message.error('请填写电话');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('请填写合法的电话');
        return;
      }
      if (!remark) {
        this.$message.error('请描述您的需求');
        return;
      }
      console.log(remark, phone, content);
      putRecord({ remark, phone, content })
        .then((res) => {
          this.$message.success('提交成功！');

          console.log(res);
        })
        .catch((err) => {
          this.$message.error('提交失败！');

          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.about-us {
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  height: 740px;
  background: #17181b;
  backdrop-filter: blur(2px);
  color: #ffffff;
  padding: 0 300px;
  padding-top: 102px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .base-info {
    .base-title {
      font-size: 24px;
    }
    .base-wrap {
      margin-top: 32px;
      .base-item {
        margin-top: 12px;
      }
    }
    .contact-wrap {
      margin-top: 32px;
      .contact-item {
        margin-top: 22px;
      }
    }
    .qrcode {
      width: 160px;
      height: 160px;
      margin-top: 33px;
      background: #fff;
    }
  }

  .form-info {
    margin-left: 78px;
    .form-title {
      font-size: 24px;
      margin-bottom: 45px;
    }
    .form-item-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      .form-input {
        box-sizing: border-box;

        width: 448px;
        height: 56px;
        margin-top: 12px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        padding-left: 16px;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
      }
      .form-textarea {
        margin-top: 17px;
        padding-top: 17px;
        width: 448px;
        height: 179px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 16px;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
      }
      .plac {
        color: #fff;
      }
      .button-info {
        margin-top: 22px;
        display: flex;
        height: 44px;
        align-items: center;
        justify-content: space-between;
        width: 448px;
        .say {
          font-size: 16px;
        }
        .btn {
          cursor: pointer;
          width: 161px;
          height: 48px;
          background: #167bed;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
